import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { error, eventId } = this.state;
    const { children } = this.props;

    if (error) {
      // render fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <a onClick={() => Sentry.showReportDialog({ eventId })}>Report feedback</a>
        </>
      );
    }
    // when there's not an error, render children untouched
    return children;
  }
}

export default ErrorBoundary;
