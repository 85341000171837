import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';
import reducers from './modules';

const middlewares = [thunk, createCookieMiddleware(Cookies)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers /* preloadedState, */,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
