// polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './utils/IEpolyfill';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import store from './redux/store';
import App from './containers/App';
import * as serviceWorker from './utils/serviceWorker';
import { version } from '../package.json';

import * as logger from './utils/Logger';

moment.tz.setDefault('Asia/Hong_Kong');

const envStage = process.env.REACT_APP_STAGE;

if (envStage.indexOf('PRD') >= 0) {
  logger.disableLogger();
}

if (process.env.REACT_APP_STAGE !== 'localhost') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: true,
    environment: envStage,
    release: version,
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
  });

  Sentry.configureScope((scope) => {
    scope.setTag('project', process.env.REACT_APP_TSPROJECTNAME);
  });

  // should have been called before using it here
  // ideally before even rendering your react app
}

const root = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
