import { createReducer } from '@reduxjs/toolkit';
import * as types from './type';

const reducerSchema = types => {
  const initialState = {
    isLoading: false,
    list: [],
    total: 0,
    error: false,
  };

  return createReducer(initialState, {
    [types.ACTION_RESET]: () => ({
      ...initialState,
    }),
    [types.ACTION_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
      lists: action.payload,
      error: true,
    }),
    [types.ACTION_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      lists: action.payload.lists,
      total: action.payload.total,
      error: false,
    }),
    [types.ACTION_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
      params: action.payload,
      error: false,
    }),
  });
};

const reducer = reducerSchema(types);

export default reducer;
