import * as types from './type';
import { defaultReducerSchema } from '../../../utils/Helper';

const reducer = defaultReducerSchema(types, {
  isLoading: false,
  data: {},
  error: false,
});

export default reducer;
