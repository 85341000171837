/*
Redefine the console.log function in your script.
https://stackoverflow.
com/questions/1215392/how-to-quickly-and-conveniently-disable-all-console-log-statements-in-my-code
*/
let oldConsoleLog = null;

export function enableLogger() {
  if (oldConsoleLog == null) return;

  window.console.log = oldConsoleLog;
}

export function disableLogger() {
  oldConsoleLog = console.log;
  window.console.log = function() {};
}
