import React from 'react';
import { Route } from 'react-router-dom'; // Switch, Redirect
import loadable from '@loadable/component';
import ErrorBoundary from '../../../components/ErrorBoundary';

import c from '../../../config';

const ScrollToTop = loadable(() => import('../../../components/ScrollToTop'));

const PreviewDate = loadable(() => import('../../../widgets/PreviewDate'));
const Header = loadable(() => import('../../../components/Header'));
const PageBottom = loadable(() => import('../../../components/PageBottom'));
const Footer = loadable(() => import('../../../components/Footer'));
const NotificationGroup = loadable(() => import('../../../widgets/NotificationGroup'));

const Main = ({ children, availableLanguages, isUnderConstruction }) => {
  const previewDateParams = new URLSearchParams(window.location.search).has('previewDate');
  const isAppParams = new URLSearchParams(window.location.search).has('isApp');

  return (
    <>
      {previewDateParams && <PreviewDate />}
      <ScrollToTop>
        <Route
          path={`${c.routePathTo}/:locale(${availableLanguages})?`}
          component={NotificationGroup}
        />
        {!isAppParams && (
          <Route
            path={`${c.routePathTo}/:locale(${availableLanguages})?`}
            render={() => <Header isUnderConstruction={isUnderConstruction} />}
          />
        )}
        <div className="mainContent" role="main">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
        {!isAppParams && !isUnderConstruction && (
          <Route path={`${c.routePathTo}/:locale(${availableLanguages})?`} component={PageBottom} />
        )}
        {!isAppParams && (
          <Route path={`${c.routePathTo}/:locale(${availableLanguages})?`} component={Footer} />
        )}
      </ScrollToTop>
    </>
  );
};

export default Main;
