export default {
  lscsPath: 'http://cmsappdev01.hktdc.org/lscs/v1/document$',
  projectNamePath: '/default/main/',
  apiContext: '/default/main/%%PROJECTNAME%%/WORKAREA/default',
  subDirectoryPath: '/preview',
  routePathTo: `/preview/${process.env.REACT_APP_TSPROJECTNAME}`,
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  footer_js_env: '-sit',
  autoCompletePath: 'https://api-fair-uat.hktdc.com/fair-core/v1/auto-complete',
  eventPath: 'https://event-uat.hktdc.com',
  researchDomain: 'https://research-sit.hktdc.com',
  sbeProgramePath: 'https://api-cms-sit.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms-sit.hktdc.com/sbe/v1/seminar/speakers',
};
