import { createReducer } from '@reduxjs/toolkit';
import * as types from './type';

const initialState = {
  status: false,
};

const reducer = createReducer(initialState, {
  [types.ENABLE_SSO_STATUS]: (state, action) => ({
    ...state,
    status: action.payload,
  }),
});

export default reducer;
